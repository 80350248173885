<template> </template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            payment: "setting/payment"
        })
    },
    async created() {
        this.$router.replace(this.$route.fullPath.replace("/payment/", this.payment.value.use_tilled ? "/EzTimePay/" : "/stripe/"));
    }
};
</script>

<style></style>
